import { Routes } from '@angular/router';
import { AppComponent } from './app.component';

import { environment } from '@env/environment';
import { redirectToGuard } from '@app/guards';

export const routes: Routes = [
  {
    path: 'checkout',
    pathMatch: 'full',
    canActivate: [redirectToGuard],
    component: AppComponent,
    data: { redirectUrl: `${environment.redirectUrl}/checkout` },
  },
  {
    path: 'onboarding',
    pathMatch: 'full',
    canActivate: [redirectToGuard],
    component: AppComponent,
    data: { redirectUrl: `${environment.redirectUrl}/onboarding` },
  },
  {
    path: 'remarketing',
    loadComponent: () =>
      import('./pages/remarketing/remarketing.component').then(m => m.RemarketingComponent),
  },
  {
    path: 'email-sent',
    loadComponent: () => import('./pages/email-sent/email-sent.component').then(m => m.EmailSentComponent),
  },
  {
    path: 'steps',
    loadChildren: () => import('./pages/steps/steps.module').then(m => m.StepsModule),
  },
  {
    path: 'next-step',
    loadComponent: () => import('./pages/next-step/next-step.component').then(m => m.NextStepComponent),
  },
  {
    path: 'thanks',
    loadComponent: () =>
      import('./pages/thanks-download/thanks-download.component').then(m => m.ThanksDownloadComponent),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'steps',
  },
];
