import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AnotaLoaderModule, AnotaToastModule } from '@githubanotaai/design-system';
import { LoaderStore, LoaderState } from './store/loader';
import { Toast, ToastStore } from './store/toast';
import { GtmResource } from '@infra/gtm';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AnotaLoaderModule, AnotaToastModule],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  protected loader: Signal<LoaderState>;
  protected toast: Signal<Toast>;

  constructor(
    private loaderStore: LoaderStore,
    protected toastStore: ToastStore,
    _gtmResource: GtmResource,
  ) {
    this.loader = this.loaderStore.getState();
    this.toast = this.toastStore.getState();
  }
}
