import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

declare var dataLayer: any[];

@Injectable({
  providedIn: 'root',
})
export class GtmResource {
  constructor() {
    this.initializeGTM();
  }

  private initializeGTM() {
    window.gtmId = environment.gtmId;
    dataLayer = dataLayer || [];
    dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}`;
    document.head.appendChild(gtmScript);
  }

  public pushEvent(event: any) {
    dataLayer.push(event);
  }
}
