<router-outlet />
<anota-toast
  [show]="!!toast().show"
  [type]="toast().type!"
  [icon]="toast().iconName!"
  (closeToast)="toastStore.hide()">
  {{ toast().message }}
</anota-toast>
@if (loader().show) {
  <anota-loader [title]="loader().title" />
}
